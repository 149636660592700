import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

export default ({ location, data: { wordpress } }) => (
  <Layout location={location} type="archive">
    <header className="page-header">
			<h1 className="page-title">Tag Archives: <span className="page-description">{wordpress.tag.name}</span></h1>
    </header>
    {wordpress.posts && wordpress.posts.edges.map(post => (
      <article key={post.node.id} id={`post-${post.node.postId}`} className="post-1 post type-post status-publish format-standard hentry category-uncategorized entry">
        <header className="entry-header">
          <h2 className="entry-title">
            <Link to={`/${post.node.slug}`}>{post.node.title}</Link>
          </h2>
        </header>
        <div className="entry-content">
          <div dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
          <Link to={`/${post.node.slug}#more-1`} className="more-link">Continue reading<span className="screen-reader-text"> “${post.node.title}”</span></Link>
        </div>
        <footer className="entry-footer">
		      <span className="byline">

            <span className="screen-reader-text">Posted by</span>
            <span className="author vcard"><a className="url fn n" href="#">{post.node.author.name}</a></span>
          </span>
          <span className="posted-on">
            <a href="https://gutenberg.local/hello-world/" rel="bookmark">
              <time className="entry-date published" dateTime="2018-12-10T18:24:43+00:00">December 10, 2018</time>
              <time className="updated" dateTime="2018-12-12T21:13:51+00:00">December 12, 2018</time></a>
          </span>
          <span className="cat-links">
            <span className="screen-reader-text">Posted in</span>
            <a href="https://gutenberg.local/category/uncategorized/" rel="category tag">Uncategorized</a>
          </span>
          <span className="comments-link">
            <Link to={`/${post.node.slug}#comments`}>
              {post.node.commentCount === 1 ? '1 Comment' : `${post.node.commentCount === null ? 0 : post.node.commentCount} Comments`}
              <span className="screen-reader-text"> on {post.node.title}</span>
            </Link>
          </span>
        </footer>
      </article>
    ))}
  </Layout>
)

export const query = graphql`
  query($id: ID!, $tagId: String!) {
    wordpress {
      tag(id: $id) {
        name
      }
      posts(where: { tagId: $tagId }) {
        edges {
          node {
            id
            postId
            slug
            title
            excerpt
            date
            commentCount
            author {
              name
            }
          }
        }
      }
    }
  }
`
